<template>
	<div id="login">
        <div class="rl-notice">
            <span class="red">註：</span>
            <span>此賬號為普通用戶（顧客）賬號，與關聯APP“大醫”互通  如欲<a href="https://os.ectcm.com/Login" class="label-link">登入</a>或<router-link to="/os/doctor_signup" class="label-link">註冊</router-link>中醫在綫管理系統</span>
        </div>
        <div class="rl-container">
            <div class="rl-tabs">
                <router-link to="/login" class="rl-tab login current">登錄</router-link>
                <span class="divider"></span>
                <router-link to="/register" class="rl-tab register">註冊</router-link>
            </div>
            <div class="login-form">
                <div class="form-item">
                    <input type="text" v-model="loginName" class="login-input-name" placeholder="請輸入手機號碼">
                </div>
                <div class="form-item">
                    <input type="password" v-model="password" class="login-input-pwd" placeholder="請輸入賬號密碼" @keyup="onKeyUp">
                </div>
                <div class="btn-login" @click="login"><div class="lds-ring" v-if="loading"><div></div><div></div><div></div><div></div></div>{{loading ? '正在登錄...' : '立即登录'}}</div>
                <router-link to="/forgetPassword" class="forget-password">忘記密碼?</router-link>
            </div>
        </div>
        <div class="btn-home" @click="toHome">
            <span class="icon-back"></span>
            <span>返回首頁</span>
        </div>
	</div>
</template>

<script>
    import user from "@/api/user";
    import userStore from '../../store/actions';
	export default {
        data() {
            return {
                loading: false,
                loginName: '',
                password: '',
            };
        },
        mounted: function(){
        },
        methods: {
            toHome: function() {
                window.location.href = "/"
            },
            onKeyUp: function(e){
                if(e.key === 'Enter') {
                    this.login()
                }
            },
            async login() {
                if(! this.loginName.trim()) {
                    this._toast.warning(this, '請輸入手機號')
                    return
                }
                if(! /^1[3-9]{1}[0-9]{9}$/.test(this.loginName) && ! /^([5|6|8|9])\d{7}$/.test(this.loginName)) {
                    this._toast.warning(this, '手機號格式不正確')
                    return
                }
                if(! this.password.trim()) {
                    this._toast.warning(this, '請輸入賬號密碼')
                    return
                }
                if(this.password.trim().length < 6) {
                    this._toast.warning(this, '賬號密碼長度至少需要6位')
                    return
                }

                this.loading = true
                this.$store.dispatch('login', {
                    loginName: this.loginName,
                    password: this.password
                }).then(() => {
                    this.$router.push({path: '/'}); //登录成功之后重定向到首页
                    this.loading = false
                }).catch((error) => {
                    this.loading = false
					this._toast.warning(this, error || '登錄失敗, 請稍後重試！')
                })
            }
        }
	};
</script>

<style lang="scss" scoped>
    /* 注册登录 start */
    #login {
        width: 100%;
        display: flex;
        flex-direction: column;
        background-image: url(../../assets/imgs/pc/bg_login@2x.png);
        background-size: 100% 100%;
        background-repeat: no-repeat;
        background-color: #fff;
        position: relative;
    }

    #login .btn-home {
        position: absolute;
        right: 50px;
        top: 30px;
        border: 1px solid #36C4D0;
        border-radius: 10px;
        height: 50px;
        padding: 0px 23px;
        font-size: 24px;
        font-weight: bold;
        color: #36C4D0;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        user-select: none;
        cursor: pointer;
    }

    #login .btn-home:active {
        opacity: 0.7;
    }

    #login .btn-home > .icon-back {
        width: 34px;
        height: 30px;
        margin-right: 13px;
        background-size: 100% 100%;
        background-repeat: no-repeat;
        background-image: url(../../assets/imgs/pc/ic_back@2x.png);
    }
    
    #login .rl-notice {
        position: absolute;
        left: 0px;
        bottom: 24px;
        margin-left: 26px;
        font-size: 14px;
        font-weight: 400;
        color: #646464;
        display: flex;
        flex-direction: row;
    }

    #login .rl-notice > .red {
        color: #EE1C24;
    }

    #login .rl-notice .label-link {
        color: #FF8000;
        text-decoration: underline;
    }

    #login .rl-container {
        width: 50%;
        height: 100%;
        display: flex;
        flex-direction: column;
        margin-left: 50%;
    }

    #login .rl-container > .rl-content {
        width: 100%;
    }

    #login .rl-content .tab-content {
        display: flex;
        flex-direction: column;
        width: 390px;
        height: 455px;
        float: left;
        position: relative;
    }

    #login .rl-content .tab-content.forget-pwd {
        position: absolute;
        left: 0px;
        bottom: -585px;
        height: 585px;
        transition: all .2s linear;
    }

    #login .rl-container .rl-tabs {
        width: 213px;
        height: 49px;
        margin-top: 16%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: flex-start;
    }

    #login .rl-container .rl-tabs .rl-tab {
        font-size: 34px;
        font-weight: bold;
        line-height: 34px;
        color: #231F20;
        cursor: pointer;
        position: relative;
        height: 49px;
        box-sizing: border-box;
        padding-bottom: 15px;
        white-space: nowrap;
    }

    #login .rl-container .rl-tabs .rl-tab.current {
        color: #36C4D0;
    }

    #login .rl-container .rl-tabs .rl-tab::after {
        position: absolute;
        content: '';
        bottom: 0;
        width: 14px;
        height: 6px;
        background-color: transparent;
        left: 50%;
        transform: translateX(-50%);
    }

    #login .rl-container .rl-tabs .rl-tab.current::after {
        background-color: #36C4D0;
    }

    #login .rl-container .rl-tabs .divider {
        width: 1px;
        height: 16px;
        margin-top: 9px;
        margin-left: 38px;
        margin-right: 38px;
        background-color: #EDEDED;
        display: block;
    }

    #login .login-form {
        margin-top: 108px;
        display: flex;
        flex-direction: column;
        position: relative;
    }

    #login .login-form > .forget-password {
        position: absolute;
        left: 350px;
        bottom: 39px;
        text-decoration: underline;
        font-weight: 400;
        color: #636363;
        line-height: 16px;
        font-size: 16px;
        transform: translateX(-80%);
    }

    #login .login-form > .form-item {
        height: 48px;
        width: 60%;
        max-width: 540px;
        padding-left: 50px;
        margin-top: 10px;
        line-height: 48px;
        border-bottom: 1px solid #E8E8E8;
        box-sizing: border-box;
        position: relative;
    }

    #login .login-form > .form-title {
        font-size: 20px;
        font-weight: bold;
        line-height: 20px;
        color: #231F20;
        margin-left: 14px;
        margin-bottom: 40px;
        padding-top: 10px;
    }

    #login .login-form > .form-item:hover {
        border-bottom-color: #7BD5DF;
    }

    #login .login-form > .form-item:nth-child(1) {
        margin-top: 0px;
    }

    #login .login-form > .form-item::before {
        position: absolute;
        content: '';
        left: 16px;
        bottom: 15px;
        width: 18px;
        height: 17px;
        background-size: 100% 100%;
        background-repeat: no-repeat;
    }

    #login .login-form > .form-item:nth-child(1)::before {
        background-image: url(../../assets/imgs/pc/icon_dianhua@2x.png);
        width: 18px;
        height: 17px;
    }

    #login .login-form > .form-item:nth-child(2)::before {
        background-image: url(../../assets/imgs/pc/icon_mima@2x.png);
        width: 16px;
        height: 17px;
    }

    #login .login-form > .form-item > input {
        display: block;
        width: 100%;
        height: 46px;
        margin-bottom: 2px;
        color: #231F20;
        font-weight: bold;
        font-size: 16px;
        border: none;
    }

    #login .login-form > .btn-login {
        margin-top: 72px;
        width: 270px;
        height: 84px;
        font-size: 18px;
        color: #fff;
        font-weight: bold;
        text-align: center;
        background-size: 100% 100%;
        background-repeat: no-repeat;
        background-image: url(../../assets/imgs/pc/img_anniu@2x.png);
        cursor: pointer;
        display: flex;
        flex-direction: row;
        justify-content: center;
        box-sizing: border-box;
        padding-top: 24px;
    }

    /** loading start */
    .lds-ring {
        display: inline-block;
        position: relative;
        width: 32px;
        height: 32px;
    }
    
    .lds-ring div {
        box-sizing: border-box;
        display: block;
        position: absolute;
        width: 24px;
        height: 24px;
        margin: 4px;
        border: 4px solid #fff;
        border-radius: 50%;
        margin-top: 1px;
        animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
        border-color: #fff transparent transparent transparent;
    }
    
    .lds-ring div:nth-child(1) {
        animation-delay: -0.45s;
    }
    
    .lds-ring div:nth-child(2) {
        animation-delay: -0.3s;
    }
    
    .lds-ring div:nth-child(3) {
        animation-delay: -0.15s;
    }

    @keyframes lds-ring {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(360deg);
        }
    }

    /** loading end */ 
</style>
